$--lk-accent-fg: #fff;
$--lk-accent-bg: #1f8cf9;
$--lk-accent2: #3396fa;

#CallEndPage {
  .ec-wrapper {
    height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6vh;
    padding: 20px;
    text-align: center;
  }

  .ec-title {
    font-size: 34px;
  }

  .ec-text {
    font-size: 18px;
  }

  .ec-button-rejoin {
    font-size: 20px!important;
    padding: 10px 60px;
    color: $--lk-accent-fg;
    background-color: $--lk-accent-bg;
    border-radius: 6px;

    &:hover {
      background-color: $--lk-accent2;
    }
  }

  .ec-button-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .ep-google-badge {
      height: 100px;

      img {
        height: 100px;
      }
    }

    .ep-apple-badge {
      display: inline-block;
      overflow: hidden;
      border-radius: 13px;
      height: 66px;

      img {
        border-radius: 13px;
        height: 66px;
      }
    }

    .ep-web-badge {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border: 1px solid #bbb;
      border-radius: 13px;
      height: 66px;
      text-decoration: none;
      text-align: center;
      font-size: 22px;
      color: $--lk-accent-fg;
      padding: 0 50px;
      position: relative;
      top: -10px;
    }
  }

  @media(max-width: 576px) {
    .ec-wrapper {
      gap: 4vh;
    }
    .ec-title {
      font-size: 28px;
    }

    .ec-button-rejoin {
      padding: 10px 50px;
    }

    .ec-button-group {
      flex-direction: column;

      .ep-google-badge {
        height: 90px;

        img {
          height: 90px;
        }
      }

      .ep-apple-badge, .ep-web-badge {
        height: 56px;

        img {
          border-radius: 10px;
          height: 56px;
        }
      }
    }
  }
}
