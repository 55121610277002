#Loading {
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(20, 20, 20, 0.7);
  z-index: 99;
}

#Loading-inner {
  animation: spin 0.8s linear infinite;
  height: 50px;
  width: 50px;
  border: 3px solid transparent;
  border-top-color: var(--danger);
  border-radius: 100%;
}
