#root, #DummyLocalParticipant {
  height: 100vh;
  max-height: 100vh;
}

* {
  box-sizing: border-box
}

html {
  color-scheme: dark
}

#__next,body,html,main {
  width: 100%;
  height: 100%;
  margin: 0
}

#DummyLocalParticipant {
  .lk-grid-layout {
    --lk-col-count: 1!important;
    --lk-row-count: 1!important;
  }

  .lk-button.lk-focus-toggle-button {
    display: none;
  }

  .lk-participant-tile {
    .lk-participant-placeholder {
      opacity: 1;
    }
  }

  .lk-toast {
    top: 20px;

    .lk-toast-infoText {
      font-size: 18px;
      padding: 10px 40px;
    }
  }
}
