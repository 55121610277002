#VideoSpaceParticipantsPage {
  width: 100%;
  height: 100%;
  background-color: var(--lk-bg2);
  border-left: 1px solid var(--lk-border-color);

  .rotate-180 {
    transform: rotate(180deg);
  }

  .participants-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px 10px 5px;
  }

  .participants-header span {
    padding-left: 5px;
    font-size: 18px;
  }

  .participants-header img {
    height: 17px;
    width: 25px;
    position: relative;
    top: 2px;
    padding: 0 5px;
    cursor: pointer;
  }

  .participants-block {
    margin: 10px;
    padding: 10px;
    border: 1px solid var(--lk-border-color);
    border-radius: var(--lk-border-radius);
  }

  .participants-block .participants-block-title {
    color: var(--lk-fg2);
    padding-bottom: 5px;
  }

  .participants-block .participants-block-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    padding-bottom: 10px;
  }

  .participants-block .participants-block-row:not(:last-child) {
    border-bottom: 1px solid var(--lk-border-color);
  }

  .participants-block .participants-block-row > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .participants-block .participants-block-row-name {
    padding-right: 8px;
    word-break: break-word;
  }

  .participants-block .participants-block-row-button {
    padding: 0 8px 4px 8px;
    margin-right: 5px;
    border-radius: 5px;
  }

  .participants-block .participants-block-row-icon {
    height: 22px;
    width: 28px;
    padding: 0 2px;
    cursor: pointer;
  }

  .participants-block .custom-menu {
    color: #cad1d8;
    background-color: #22262c;
    border: 1px solid #333;
  }

  .participants-block .custom-menuitem {
    background-color: #22262c !important;
  }

  .participants-block .custom-menuitem:hover {
    background-color: #2d323a !important;
  }

  .participants-block .participants-block-row-icon.dot {
    height: 12px;
    width: 18px;
  }

  @media only screen and (min-width: 900px) {
    .participants-block .participants-block-row-button {
      flex: 2;
    }
  }

  @media (max-width: 992px) {
    .participants-block .participants-block-row-button {
      padding: 0 4px 2px 4px;
      font-size: 14px;
    }
  }
}
