#ConfirmDialog {
  display: grid;
  place-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(20, 20, 20, 0.9);
  z-index: 98;
}

#ConfirmDialog .dialog-wrapper {
  min-width: 30%;
  max-width: 40%;
  color: #cad1d8;
  background-color: #22262c;
  border: 1px solid #333;
  border-radius: 10px;
}

#ConfirmDialog .dialog-title {
  font-size: 18px;
  padding: 15px;
  border-bottom: 1px solid #333;
}

#ConfirmDialog .dialog-content {
  padding: 15px;
  word-break: break-all;
}
#ConfirmDialog .dialog-footer {
  padding: 15px;
  border-top: 1px solid #333;
}

#ConfirmDialog .dialog-footer > div {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 15px;
}
#ConfirmDialog .dialog-footer .lk-disconnect-button {
  padding: 4px 15px 5px 15px;
}
#ConfirmDialog .dialog-footer .lk-disconnect-button.info {
  color: var(--lk-accent-fg);
  background-color: var(--lk-accent-bg);
}
#ConfirmDialog .dialog-footer .lk-disconnect-button.info:hover {
  background-color: var(--lk-accent3);
}
#ConfirmDialog .dialog-footer .lk-disconnect-button.danger {
  color: var(--lk-danger-fg);
  background-color: var(--lk-danger);
}
#ConfirmDialog .dialog-footer .lk-disconnect-button.danger:hover {
  background-color: var(--lk-danger3);
}
#ConfirmDialog .dialog-footer .lk-disconnect-button.success {
  color: var(--lk-fg);
  background-color: var(--lk-connection-excellent);
}
#ConfirmDialog .dialog-footer .lk-disconnect-button.success:hover {
  background-color: var(--lk-success);
}

@media only screen and (max-width: 572px) {
  #ConfirmDialog .dialog-wrapper {
    max-width: 90%;
    min-width: 50%;
  }
}
