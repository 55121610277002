#JoinRoomPage {
  &.wrapper {
    display: grid;
    place-items: center;
    height: 100%;
    background-color: var(--lk-bg);
  }

  .lk-form-control[aria-invalid=true] {
    border-color: var(--lk-danger);
  }

  .lk-form-control-error {
    color: var(--lk-danger);
    position: relative;
  }

  button[disabled] {
    cursor: default;
  }

  .title {
    color: #a6a6a6;
  }
}
