#CustomVideoConference {
  .lk-participant-placeholder.custom {
    .image-wrapper {
      height: 320px;
      width: 320px;
    }
  }

  .lk-chat-header {
    justify-content: space-between;

    .lk-close-button {
      transform: none;
    }
  }
}
